@font-face {
    font-family: "Dax";
    
    src:  url("../fonts/Dax_Regular.otf"),
          url("../fonts/Dax_Regular.ttf") format("truetype"),
          url("../fonts/Dax_Bold.otf"),
          url("../fonts/Dax_Bold.ttf") format("truetype"),
          url("../fonts/Dax_Bold_Italic.ttf") format("truetype"),
          url("../fonts/Dax_BoldItalic.otf"),
          url("../fonts/Dax_Italic.otf"),
          url("../fonts/Dax_Italic.ttf") format("truetype");
  
    font-style: normal;
  }

html{
    height: 100%
}
body {
    /* //, sans-serif */
    font-family: 'Dax'!important;
    overflow: hidden;
    overflow-y: scroll;
    font-size:14px;
    color: rgb(105, 105, 105);
    height: 100%
}


.bg-vj {
    background-color: #C30E13;
    z-index: 9999;
    position: relative;
}

.hope .full-wrapper{
    background: #fff;
    max-width: 960px;
    width: 100%;
    margin: 35px auto;
    border-radius: 6px;
    overflow: hidden;
}
p { margin: 0 0 10px; }
.lt-ie9 .animated {
    opacity: 1 !important;
    -webkit-animation: none !important;
    animation: none !important;
    transform: none !important;
}
img {
    max-width: 100%;
    height: auto;
}
ul{
    margin:0;
}
a { outline: none !important }
h1, h2, h3, h4, h5, h6 {
    font-family: 'myFirstFont', sans-serif;
    margin:10px 0 15px 0;
}
h1 { font-size: 72px; }
h2 { font-size: 48px; }
h3 { font-size: 36px; }
h4 { font-size: 24px; }
h5 { font-size: 18px; }
h6 { font-size: 14px; }
.text-right { text-align: right; }
.text-left { text-align: left; }
.text-center { text-align: center; }
.btn-default{
    background:#46505c;
    color:#fff;
}
.btn-ref{
    text-align: center;
    margin: 10px auto;
    border-radius: 25px;
}
.img-center{
    display: block;
    margin: 10px auto;
}
.text-justify{
    text-align:justify;
}
.btn{
    /*padding:15px 55px;*/
    background-color: #da251c;
    background: -webkit-linear-gradient(#da251c,#da251c);
    background: -moz-linear-gradient(#da251c,#da251c);
    background: -ms-linear-gradient(#da251c,#da251c);
    background: -o-linear-gradient(#da251c,#da251c);
    background: linear-gradient(#da251c,#da251c);
    box-shadow: inset 0px 1px 0px #cb6a71,inset 0 -1px 2px #7e1c23;
    -pie-background: linear-gradient(#b22832,#b22832);
    color: #fff;
    border-radius:0px;
    font-family: 'myFirstFont';
    text-align: center;
    border:none;
    text-transform:inherit;
    font-size: 16px;
    position: relative;
    font-weight: bold;
    text-transform: uppercase;
    /* margin: 10px auto; */
}
.btn-tranparent{
    border:1px solid #7cbf1d;
    border-radius:3px;
    text-decoration:none !important;
}

.btn:hover{
    background-color: #b22832;
    background: -webkit-linear-gradient(#b22832,#b22832);
    background: -moz-linear-gradient(#b22832,#b22832);
    background: -ms-linear-gradient(#b22832,#b22832);
    background: -o-linear-gradient(#b22832,#b22832);
    background: linear-gradient(#b22832,#b22832);
    box-shadow: inset 0px 1px 0px #cb6a71,inset 0 -1px 2px #7e1c23;
    color: #fff;
}
.text-extralight{font-weight:100 !important;}
.text-light{font-weight:300 !important;}
.text-semibold{font-weight:600 !important;}
.text-bold{font-weight:700 !important;}
.text-extrabold{font-weight:800 !important;}
.text-uppercase{text-transform:uppercase !important;}
.text-lowercase{text-transform:lowercase !important;}
.bg1{background: #FB9438;}
.bg2{background: #D0463B;}
.bg3{background: #BB3540;}
/*===============================================
Form Styling
=================================================*/
form{
    margin-bottom:30px;
}
form .btn{
    /*padding-top:10px;*/
    /*padding-bottom:10px;*/
    /*margin-right: 5px;*/
}
input[type="text"],input[type="tel"],input[type="email"],input[type="password"],textarea,select{
    width:100%;
    border: 0;
    border:none;
    height:44px;
    padding:10px 15px;
    text-transform:inherit;
    margin-bottom: 20px;
    background-color: #EFF2F5;
    color: #000;
    border-radius: 2px;
}
select
input[type="text"].tranparent,input[type="tel"].tranparent,input[type="email"].tranparent,input[type="password"].tranparent,textarea.tranparent{
    background:none;
    border:1px solid #7cbf1d;
    color:#7cbf1d;
}
textarea{
    height:180px;
    padding:25px 35px;
}
label{
    color:#8e9297;
}
label.tranparent{
    color:#8e9297;
}

.bg-header {
    background-image: url(/images/header_corner.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 110px 70px;
    background-color: #e61f20;
    font-size: 1.1rem;
}

.btn-yellowGradient .dropdown-toggle{
    background: linear-gradient(#fad305, #ffdd00, #ffdd00, #fad305);
    color: #343a40!important;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.sticky-top{
    top: 0;
    z-index: 1020;
    box-shadow: 0 1px 5px #000;
}



/*.nav li ul*/
/*{*/
    /*display:none;*/
    /*margin:0;*/
    /*padding:0;*/
    /*background-color:#ee1c25;*/
    /*width:260px;*/
    /*position:absolute;*/
    /*left:0;*/
    /*z-index:1000*/
/*}*/
/*.nav li ul li*/
/*{*/
    /*display:block;*/
    /*padding:10px;*/
    /*width:100%;*/
    /*border-bottom:solid 1px #f72c35;*/
    /*margin-left:0;*/
    /*padding-left:15px*/
/*}*/
/*.nav li ul li a*/
/*{*/
    /*font-family:"myb",Tahoma, Geneva, sans-serif;*/
    /*font-size:15px;*/
    /*color:#fff;*/
    /*text-transform:uppercase;*/
/*}*/
/*.nav li ul li a:hover*/
/*{*/
    /*color:#FFF*/
/*}*/
/*==============================================
Spacing Helping Classes
===============================================*/
.mb-10{ margin-bottom:10px;}
.mb-15{ margin-bottom:15px;}
.mb-20{ margin-bottom:20px;}
.mb-25{ margin-bottom:25px;}
.mb-30{ margin-bottom:30px;}
.mb-35{ margin-bottom:35px;}
.mb-40{ margin-bottom:40px;}
.mb-45{ margin-bottom:45px;}
.mb-50{ margin-bottom:50px;}
.mt-10{ margin-top:10px;}
.mt-15{ margin-top:15px;}
.mt-20{ margin-top:20px;}
.mt-25{ margin-top:25px;}
.mt-30{ margin-top:30px;}
.mt-35{ margin-top:35px;}
.mt-40{ margin-top:40px;}
.mt-45{ margin-top:45px;}
.mt-50{ margin-top:50px;}

.overlay {
    background: rgba(8,8,8,0.78);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
}
.overlay-inner{position: relative;}
/*=====Main====*/
.hope header{padding: 25px 0;}

.join-us{
    background: url(/images/background_3.jpg) top center no-repeat;
    background-size: cover;
    padding-top: 50px;
    position: relative
}
.contact-us-bx{
    overflow: hidden;
    padding-bottom: 50px
}
.join-us .bg-line-footer{
    position: absolute;
    bottom: -13px;
    left: 0;
    width: 100%;
}
.title-contact {
    color: #1f4995;
    margin-bottom: 40px;
    font-size: 160%;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    line-height: normal;
}
.inner-form-contact {
    padding: 30px 45px;
    /*background: #204995;*/
    position: relative;
    /*margin: 0 15%*/
}
.img-mua-lan {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 30%;
    margin-left: -20%;
}
.img-chu-cuoi {
    position: absolute;
    bottom: 10px;
    right: 10px;
    width: 20%;
    margin-right: -25%;
}
.choose-servce {
    padding: 2px;
    border: 1px dashed #95ddf2;
}
.inner-choose-servce {
    background: url(/images/bg-serve.png) repeat-y center center;
    background-size: contain;
    padding: 30px;
}
.title-choose-servce {
    margin: 0;
    margin-bottom: 20px;
    font-size: 110%;
    text-align: center;
    color: #1f4995;
    font-weight: bold;
    text-transform: uppercase;
}

.item-choose-service{
    /* padding-top: 2%; */
    display: block;
    font-size: 15px;
    color: #212020;
}

.lb-choose-service {
    display: block;
    font-size: 15px;
    color: #4f4e4b;

}
.lb-choose-service input[type="checkbox"] {
    display: none;
}
.lb-choose-service .text-choose{
    display: block;
    padding-left: 20px;
    position: relative;
}
.lb-choose-service .text-choose:before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7px;
    border-radius: 50%;
    border: 1px solid #616161;
}
.lb-choose-service .text-choose:after {
    content: "";
    display: none;
    width: 10px;
    height: 10px;
    background: transparent;
    position: absolute;
    top: 50%;
    left: 2px;
    margin-top: -5px;
    border-radius: 50%;
    background: #ee7782;
    background: -moz-linear-gradient(to top, #ee7782 0%, #e10f30 100%);
    background: -webkit-linear-gradient(to top, #ee7782 0%,#e10f30 100%);
    background: linear-gradient(to bottom, #ee7782 0%,#e10f30 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee7782', endColorstr='#e10f30',GradientType=0 );
}
.lb-choose-service input[type=checkbox]:checked + .text-choose:before{
    border: 1px solid #993333
}
.lb-choose-service input[type=checkbox]:checked + .text-choose:after{
    display: block;
}
.hope header .phone{
    font-size: 28px;
    font-weight: bold;
    color: #696969;
}
.color-blue {
    color: #22297a;
}
.color-white {
    color: #fff;
}
.hope header .phone .fa{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background: #BB3540;
    text-align: center;
    color: #fff;
    line-height: 50px;
    font-size: 30px;
    margin-right: 10px;
}
.no-pad{padding: 0;}
.hope .donate{color: #fff; position: relative; padding: 40px 20px 50px 20px;}
.hope .donate h4{
    font-family: open sans;
    font-weight: bold;
    letter-spacing: 5px;
    position: relative;
    margin-bottom: 55px;
}
.hope .donate .fa{
    position: absolute;
    right: 20px;
    top: 40px;
    font-size: 80px;
}
.hope .donate h4:after{
    content: '';
    background: #F5E1E3;
    height: 4px;
    width: 160px;
    position: absolute;
    left: 0;
    bottom: -20px;
}
.hope .join-us h2{    color: rgb(208, 70, 59);}
.hope .join-us h2 span{
    color: rgb(128, 128, 128);
    font-size: 36px;
    border-bottom: 3px solid rgb(208, 70, 59);
}
.hope .join-us li{
    display: block;
    position: relative;
    margin-bottom: 32px;
}
.hope .join-us ul{padding-left: 50px; margin-top: 50px; }
.hope .join-us li:nth-child(1):before{background: #FB9438;}
.hope .join-us li:nth-child(2):before{background: #D0463B;}
.hope .join-us li:nth-child(3):before{background: #BB3540;}
.hope .supporters{background: #F5F5F5; padding: 20px 0;}
.hope .supporters ul{padding: 0;}
.hope .supporters ul li{
    display: inline-block;
    margin: 20px 16px;
}
.hope .supporters .info{
    border-right: 10px solid #BB3540;
    min-height: 100px;
    text-align: center;
    padding-top: 18px;
}
.hope .supporters h4 span{color: #BB3540}
.hope .testimonials h3 span{color: #BB3540; font-size: 48px;}
.hope .testimonials{position: relative; background: url(/demo-data/bg2.jpg); background-size: cover; padding-bottom: 50px;}
.hope .testimonials .overlay{background: rgba(255,255,255,0.97);}
.hope .testimonials .inner{border-radius: 50%; color: #fff; position: relative; margin: 0 auto;}
.hope .testimonial.style1 .inner{
    height: 200px;
    max-width: 200px;
    padding: 60px 20px 20px 20px;
}
.hope .testimonial.style2 .inner{
    height: 226px;
    max-width: 226px;
    padding: 70px 25px 25px 22px;
}
.hope .testimonial.style3 .inner{
    height: 250px;
    max-width: 250px;
    padding: 80px 30px 30px 30px;
}
.hope .testimonial figure img{width: 100%; height: 100%;}
.hope .testimonial figure{
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: 0;
}
.hope .testimonial.style1 {margin-top: 50px;}
.hope .testimonial.style2 {margin-top: 24px;}
.hope .timeline{
    background:  #BB3540;
    color: #fff;
    position: relative;
}
.hope .timeline:after{
    content: '';
    background: #fff;
    height: 100%;
    width: 4px;
    position: absolute;
    left: 50%;
    top: 0;
    margin-left: -2px;
}
.hope .timeline .line{
    width: 50%;
    float: left;
    padding: 50px 30px;
    position: relative;
}
.hope .timeline .line:nth-child(2){ margin-top: 90px; }
.hope .timeline .line:nth-child(even):before{
    content: '';
    position: absolute;
    left: -12px;
    top: 60px;
    height: 25px;
    width: 25px;
    border: 3px solid #fff;
    border-radius: 50%;
    background: #BB3540;
    z-index: 99;
}
.hope .timeline .line:nth-child(odd):before{
    content: '';
    position: absolute;
    right: -9px;
    top: 68px;
    height: 16px;
    width: 16px;
    border: 3px solid #fff;
    border-radius: 50%;
    background: #BB3540;
    z-index: 99;
}
.modal-header {
    background: #1f4995;
    margin-bottom: 20px;
}
.modal-title {
    text-align: center;
    font-size: 120%;
    text-transform: uppercase;
    color: #FFF
}
.modal-header .close{
    color: #FFF
}
.hope .timeline .line span{
    font-weight: bold;
    display: block;
    margin-bottom: 18px;
}
.active-text {
    color: #dc0102;
    /* font-weight:700;*/
}
.button {
    background-color: #dc0102;
    -webkit-border-radius: 10px;
    border-radius: 50px;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    padding: 3px 20px;
    text-align: center;
    text-decoration: none;
    border: 5px solid rgba(255,255,255,0.9);
}

.buttonbasic {
    background-color: #ff0000;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    border: none;
    color: #FFFF02;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    padding: 3px 20px;
    text-align: center;
    text-decoration: none;
    /* border: 5px solid rgba(255,255,255,0.9); */
}

.buttonbasic:hover{
    color : white;
}

.next{
    float: right;
    background-color: #ffdd15;
    color: #55534c;
    border-radius: 10px;
    font-size: 25px;
    padding: 3px 50px;
}

.button-ct {

    background-color: #22297a;

    -webkit-border-radius: 10px;

    border-radius: 50px;

    border: none;

    color: #FFFFFF;

    cursor: pointer;

    display: inline-block;

    /* font-family: Arial;*/

    font-size: 24px;

    padding: 10px 60px;

    text-align: center;
    text-decoration: none;
    border: 5px solid rgba(255,255,255,0.9);
}
.circle.active{width:85px;height:85px;border-radius:50px;background:linear-gradient(to bottom, #dc0102, #b91414) ; margin:0 auto; display:block}
/*.circle:hover {
    width:85px;height:85px;border-radius:50px; margin:0 auto; display:block
}*/
.hope footer{padding: 20px 0; background: #fff}
.hope footer span{display: block;}
.hope footer .logo{display: table; text-align: center; margin: 18px auto 0 auto;}
.height-fix{
    height: 70px;
}
.banner-mobile {
    position: relative;
    z-index: 1;
}
.banner-mobile:before {
    content: "";
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0, .5);
}

.img-1 {
    margin-top: 5px;
}
.navbar-nav > li > a {
    margin-top:18px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 30px
}
.navbar-default .navbar-nav>li>a {
    color: #FFF;
}
.hd{
    background-image: url(/images/header_corner.png);
    background-position: right bottom;
    background-repeat: no-repeat;
}
.navbar {
    font-family: "myb",Tahoma, Geneva, sans-serif;
    min-height: 80px;
    /*border-bottom: 2px solid #f8ed40;*/
    margin-bottom: 0;
    /*background: #ed1c24;*/
    background: transparent;

}
.navbar-toggle{
    padding: 22px 10px;
}
.navbar-right {
    margin-right: 0px;
}
.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
    color: #FFF;
    background-color: #ed1c24;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
}
.nav-menu-sub .nav-link {
    display: block;
    position: relative;
}
.nav-menu-sub .nav-link .nav-img {
    display: block;
}
.nav-menu-sub .nav-link .nav-text {
    display: none;
}
.list-deal{
    margin-bottom: 50px;
    margin-top : 50px

}
.wrap-home{
    /* background: url(/images/bg.png) no-repeat center top; */
    background-size: cover;
    position: relative
}
.wrap-home.thanks {
    min-height: 100%;
}
.title-thanks {
    font-size: 140%;
    color: #FFF;
    text-transform: uppercase;
    margin: 60px 0;
    padding: 10px;
    background: rgb(34, 64, 153);
    border: 1px dashed #ccc
}
.text-no-wrap {
    white-space: nowrap;
    display: inline-block;
}
/* Sweep To Top */
.hvr-sweep-to-top {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-border-radius: 5px 5px 0 0;
    border-radius: 5px 5px 0 0;
}
.hvr-sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #ee1c25;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    -webkit-border-radius: 10px 10px 0 0;
    border-radius: 10px 10px 0 0;
}
.hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
    color: white !important;
}
.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}
.button:hover {
    color: #FFFFFF;
    background-color: #dc0102;
    text-decoration: none !important;
}
.button-ct:hover {
    color: #FFFFFF;
    background-color: #22297a;
    text-decoration: none !important;
}


@-webkit-keyframes glowing-ct {
    0% { background-color: #22297a; -webkit-box-shadow: 0 0 3px #22297a; }
    50% { background-color: #22297a; -webkit-box-shadow: 0 0 10px #22297a; }
    100% { background-color: #22297a; -webkit-box-shadow: 0 0 3px #22297a; }
}

@-moz-keyframes glowing-ct {
    0% { background-color: #22297a; -moz-box-shadow: 0 0 3px #22297a; }
    50% { background-color: #22297a; -moz-box-shadow: 0 0 10px #22297a; }
    100% { background-color: #22297a; -moz-box-shadow: 0 0 3px #22297a; }
}

@-o-keyframes glowing-ct {
    0% { background-color: #22297a; box-shadow: 0 0 3px #22297a; }
    50% { background-color: #22297a; box-shadow: 0 0 10px #22297a; }
    100% { background-color: #22297a; box-shadow: 0 0 3px #22297a; }
}

@keyframes glowing-ct {
    0% { background-color: #22297a; box-shadow: 0 0 3px #22297a; }
    50% { background-color: #22297a; box-shadow: 0 0 10px #22297a; }
    100% { background-color: #22297a; box-shadow: 0 0 3px #22297a; }
}

.glowing-ct {
    -webkit-animation: glowing-ct 1500ms infinite;
    -moz-animation: glowing-ct 1500ms infinite;
    -o-animation: glowing-ct 1500ms infinite;
    animation: glowing-ct 1500ms infinite;
}
@-webkit-keyframes glowing {
    0% { background-color: #dc0102; -webkit-box-shadow: 0 0 3px #dc0102; }
    50% { background-color: #dc0102; -webkit-box-shadow: 0 0 10px #dc0102; }
    100% { background-color: #dc0102; -webkit-box-shadow: 0 0 3px #dc0102; }
}

@-moz-keyframes glowing {
    0% { background-color: #dc0102; -moz-box-shadow: 0 0 3px #dc0102; }
    50% { background-color: #dc0102; -moz-box-shadow: 0 0 10px #dc0102; }
    100% { background-color: #dc0102; -moz-box-shadow: 0 0 3px #dc0102; }
}

@-o-keyframes glowing {
    0% { background-color: #dc0102; box-shadow: 0 0 3px #dc0102; }
    50% { background-color: #dc0102; box-shadow: 0 0 10px #dc0102; }
    100% { background-color: #dc0102; box-shadow: 0 0 3px #dc0102; }
}

@keyframes glowing {
    0% { background-color: #dc0102; box-shadow: 0 0 3px #dc0102; }
    50% { background-color: #dc0102; box-shadow: 0 0 10px #dc0102; }
    100% { background-color: #dc0102; box-shadow: 0 0 3px #dc0102; }
}

.glowing {
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
}
.modal-footer{
    border: none;
}
.modal-header{
    border: none;
}
.mask
{
    background-color:#273f93;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    /* min-height: 440px;*/
    position: relative;
}
.inner-mask
{
    padding-top:60px;
    padding-left:15px;
    padding-right:15px;
    padding-bottom:60px;
}
.btn-close-mask
{
    display:block;
    padding: 10px !important;
    background-color:#db282e !important;
    opacity: 1;
    border-radius: 5px;
    border:1px solid #db282e !important;;
    z-index: 1100;

}

/*.navbar-brand .img-logo{*/
    /*display: inline-block;*/
    /*vertical-align: middle;*/
/*}*/
/*.navbar-brand {*/
    /*padding: 22px 20px 15px 20px;*/
    /*height: auto;*/
/*}*/
.slo
{
    color:#FFF;
    font-size:24px;
    text-transform:uppercase;
    font-family:"myb",Tahoma, Geneva, sans-serif;
    display: inline-block;
    vertical-align: middle;
}
.btn-reg-mask
{
    background-color:#db282e;
    display:block;
    width:100%;
    padding-top:10px;
    padding-bottom:10px;
    text-align:center;
    -webkit-border-radius: 0 0 10px 10px;
    border-radius: 0 0 10px 10px;
    font-family:"myb",Tahoma, Geneva, sans-serif;
    color:#fff;
    text-align:center;
    text-transform:uppercase;
    position:absolute;
    bottom:0;
    left:0;
}
.title-mask
{
    font-family:"myb",Tahoma, Geneva, sans-serif;
    color:#fff;
    text-transform:uppercase;
    font-size:18px;
    line-height:24px;
    font-weight:normal;
    margin-bottom:10px;
}
.date-mask
{
    font-family:"myb",Tahoma, Geneva, sans-serif;
    color:#fff;
    text-transform:uppercase;
    font-size:16px;
    font-weight:normal;
    font-style:italic;
    margin-top:5px;
    margin-bottom:10px;
}
.intro-mask
{
    text-align:justify;
    line-height:20px;
    color:#fff;
    font-size:14px;
    font-family:Arial, Helvetica, sans-serif
}
.intro-mask>div
{
    margin-bottom:20px;
}
.tbl
{
    padding-left:20px;
    padding-right:20px;
    border:solid 1px #fff;
    margin-top:10px;
    font-size:14px;
    line-height:18px;

}
.tb-row
{
    background-color:transparent;
    text-align:center;
    font-family:"myr",Verdana, Geneva, sans-serif;
    border-bottom:solid 1px #fff;
    vertical-align: middle;
    line-height: 1.8;
}
.tbl-head{
    background: #22297a;
    color: #fff;
}
.tbl-col
{
    background: #7cc4ff;
    color: #030303;
    font-weight: 700;
}

.br-left
{
    border-left:solid 1px #fff;
}
.bg-line-footer
{
    background-image:url(/images/line-footer.png);
    height:31px;
}
/*footer line*/
.footer-line01 {
    background: -webkit-linear-gradient(to left, transparent 70%, #e91c24 30%);
    background: -moz-linear-gradient(to left, transparent 70%, #e91c24 30%);
    background: -ms-linear-gradient(to left, transparent 70%, #e91c24 30%);
    background: linear-gradient(to left, transparent 70%, #e91c24 30%);
}
.footer-line01-bg-img
{
    width:65%;
    height:17px;
    margin:auto;
    background-image:url(/images/footer01.png);
    background-position: right top;
    background-repeat:no-repeat;
}
.footer-line02-bg-img
{
    width:43%;
    height:17px;
    margin:auto;
    background-image:url(/images/footer02.png);
    background-position: right top;
    background-repeat:no-repeat;
}

.footer-line02 {
    background: -webkit-linear-gradient(to left,#224096 70%, transparent 30%);
    background: -moz-linear-gradient(to left,#224096 70%, transparent 30%);
    background: -ms-linear-gradient(to left,#224096 70%, transparent 30%);
    background: linear-gradient(to left,#224096 70%, transparent 30%);
    height:17px;
}
.footer
{
    position: relative;
    z-index: 999
}
.footer:before{
    content: "";
    display: block;
    height: 15px;
    background: url(/images/line-footer-red.png) no-repeat center right;
    background-size: cover;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0
}
.inner-footer{
    padding-bottom:20px;
    position: relative;
}
.inner-footer:before{
    content: "";
    display: block;
    height: 15px;
    background: url(/images/line-footer-blue.png) no-repeat center right;
    background-size: cover;
    margin-bottom: 30px
}
.web
{
    background-image:url(/images/web.png);
    background-repeat:no-repeat;
    padding-left:40px;
    font-size:16px;
    line-height: 30px;
    display: block;
}
.web
{
    color:#273f93;
    font-family:"myb",Tahoma, Geneva, sans-serif;
}
.web a:hover
{
    text-decoration:none;
    color:#db282e;
}

.phone
{
    background-image:url(/images/phone.png);
    background-repeat:no-repeat;
    padding-left:40px;
    font-size:16px;
    line-height:30px;
    display: block;
}
.phone
{
    color:#273f93;
    font-family:"myb",Tahoma, Geneva, sans-serif;
}
.phone a:hover
{
    text-decoration:none;
    color:#db282e;

}
.theochungtoi
{
    color:#273f93;
    font-family:"myb",Tahoma, Geneva, sans-serif;
    display: block;
    font-size:16px;
    line-height:36px;
}
.text-socus{
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}
.face
{
    display: inline-block;
    vertical-align: middle;
    background-image:url(/images/face.png);
    width: 32px;
    height: 32px;
    background-position:0 0;
    margin-right:5px;
}
.face:hover
{
    background-position:0 -32px;
}

.you
{
    display:inline-block;
    vertical-align: middle;
    background-image:url(/images/you.png);
    width: 32px;
    height: 32px;
    background-position:0 0;
}
.you:hover
{
    background-position:0 -32px;
}
.item-footer {
    display: inline-block;
    vertical-align: middle;
}
.item-footer+.item-footer {
    margin-left: 5%;
}
.bg-line-footer
{
    background-image:url(/images/line-footer.png);
    background-repeat:no-repeat;
    background-position:center top;
    height:31px;
    background-size: cover;
}
.img-canh-dao{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 22%;
    user-select: none;
}
.img-canh-mai{
    position: absolute;
    top: 150%;
    right: 0;
    z-index: 99;
    width: 22%;
    margin-top: -2%;
    z-index: 0;
    user-select: none;
}
.img-canh-mai.half{
    width: 45%
}
.img-canh-mai.vay-ca-nhan {
    width: 30%;
}
.img-canh-mai img{
    width: 100%
}
.top-section {
    position: relative;
    z-index: 2;
    /*margin-bottom: 100px*/
}
.inner-top-section {
    position: relative;
}
.wrap-parallax {
    position: relative;
}
.mouse-bg {
    left: 0px;
    top: 0;
    position: absolute;
    width: 50%;
}
.navbar-default .navbar-toggle {
    border-color: #FFF;
}
.navbar-default .navbar-toggle .icon-bar {
    background-color: #FFF;
}
.parallax {
    position: absolute;
    top: 50px;
    left: 45%;
    width: 100%;
    margin-left: -50%;
}
.home-page .parallax,
.thanks-page .parallax{
    width: 180%;
    margin-left: -70%;
}
.week-gold{
    position: relative;
    z-index: 0
}
.img-hill{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    user-select: none;
}
.img-hill,.img-hill-top img{
    width: 100%
}

.img-hill-top{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    user-select: none;
}

.hill-thanks{
    height: 200px
}
.wellcom-year {
    padding: 10px;
    position: absolute;
    bottom: 60px;
    left: 100%;
    width: 100%;
    margin-left: 10%;
}

.wellcom-year .go{
    width: 70rem;
}

.inner-week-gold{
    position: relative;
    z-index: 1
}
.title-genarel-deal {
    margin-bottom: 20px;
    padding-right: 10%;
    position: relative;
    z-index: 1;
}
.note-deal {
    color: #333;
    position: relative;
    z-index: 1;
    font-weight: bold
}
.note-deal .time-deal{
    color: #20409a;
    display: block;
    margin-bottom: 10px;
    font-size: 1em;
}
.note-deal.tranfer .time-deal{
    color: #333;
}
.note-deal .txt-blue{
    color: #20409a;
}
.sub-deal+.sub-deal {
    margin-top: 20px;
}
.list-node-deal {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
}
.list-node-deal li{
    padding-left: 20px;
    position: relative;
}
.list-node-deal li:before{
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    left: 0;
    width: 6px;
    height: 12px;
    background: url(/images/arrow-left.png) no-repeat center center;
    background-size: contain;
}
.list-node-deal li+li{
    margin-top: 10px
}
.title-sub-deal {
    position: relative;
    background: red;
    background: -moz-linear-gradient(to left, red 0%, red 100%);
    background: -webkit-linear-gradient(to left, red 0%,red 100%);
    background: linear-gradient(to right, red 0%,red 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f9a51a', endColorstr='#fbb612',GradientType=1 );
    /* font-size: 110%; */
    padding: 10px 15px 10px 20px;
    color: white;
    font-weight: bold;
}
.title-sub-deal:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    right: 100%;
    bottom: 0;
    /* width: 100000px; */
    background: #f9a51a;
    /*background: -moz-linear-gradient(to left, #f9a51a 0%, #fbb612 100%);*/
    /*background: -webkit-linear-gradient(to left, #f9a51a 0%,#fbb612 100%);*/
    /*background: linear-gradient(to right, #f9a51a 0%,#fbb612 100%);*/
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#222966', endColorstr='#2a376a',GradientType=1 );
    margin-right: -1px;
}
.title-sub-deal:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: -15px;
    width: 24px;
    bottom: 0;
    /*background: url(/images/arrow-three.png) no-repeat center center;*/
    background-size: contain;
}
.title-struct-deal {
    font-size: 100%;
    color: #20409a;
    font-weight: bold;
}
.description-deal{
    position: relative;
    z-index: 100;
    text-align: justify;
}
.description-deal .light-bxo{
    position: absolute;
    top: -140%;
    left: -30%;
    z-index: 0;
    width: 150%;
}
.table-struct-deal {}
.week-gold .list-btn{
    padding: 20px 0 60px 0
}
.bottom-form {
    margin-bottom: 20px;
}
.btn-wide{
    display: block;
    background: #eb3d3d;
    background: -moz-linear-gradient(to top, #eb3d3d 0%, #c41717 100%);
    background: -webkit-linear-gradient(to top, #eb3d3d 0%,#c41717 100%);
    background: linear-gradient(to bottom, #eb3d3d 0%,#c41717 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb3d3d', endColorstr='#c41717',GradientType=0 );
    text-align: center;
    border-radius: 30px;
    border: 3px solid #FFF;
    padding: 10px 20px;
    text-transform: uppercase;
    width: 100%;
    color: #FFF;
}
.week-gold .btn-deal{
    display: inline-block;
    width: 40%;
    background: #eb3d3d;
    background: -moz-linear-gradient(to top, #eb3d3d 0%, #c41717 100%);
    background: -webkit-linear-gradient(to top, #eb3d3d 0%,#c41717 100%);
    background: linear-gradient(to bottom, #eb3d3d 0%,#c41717 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eb3d3d', endColorstr='#c41717',GradientType=0 );
    text-align: center;
    border-radius: 30px;
    border: 3px solid #FFF;
    padding: 10px 0;
    text-transform: uppercase;
}
.week-gold .btn-deal:hover, .btn-wide:hover{
    color: #FFF;
    text-decoration: none;
    background: #c41717;
    background: -moz-linear-gradient(to top, #c41717 0%, #eb3d3d 100%);
    background: -webkit-linear-gradient(to top, #c41717 0%,#eb3d3d 100%);
    background: linear-gradient(to bottom, #eb3d3d 0%,#eb3d3d 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c41717', endColorstr='#eb3d3d',GradientType=0 );
}
.week-gold .btn-deal + .btn-deal{
    margin-left: 10px
}
.left-week-gold{
    position: relative;
}
.nav-menu-sub .nav-item {
    display: inline-block;
    vertical-align: top;
    width: 16%;
}
.nav-menu-sub .nav-item+.nav-item {
    margin-left: 20px;
}
.nav-menu-sub .nav-item+.nav-item{
    width: 12%;
}
.nav-menu-sub .nav-item+.nav-item+.nav-item{
    width: 16%;
}
.nav-menu-sub .nav-item+.nav-item+.nav-item+.nav-item{
    width: 12%;
}
/* Buzz */
@-webkit-keyframes hvr-buzz {
    50% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    100% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
}
@keyframes hvr-buzz {
    50% {
        -webkit-transform: translateX(3px) rotate(2deg);
        transform: translateX(3px) rotate(2deg);
    }
    100% {
        -webkit-transform: translateX(-3px) rotate(-2deg);
        transform: translateX(-3px) rotate(-2deg);
    }
}
.hvr-buzz {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
}
.hvr-buzz:hover, .hvr-buzz:focus, .hvr-buzz:active {
    -webkit-animation-name: hvr-buzz;
    animation-name: hvr-buzz;
    -webkit-animation-duration: 0.15s;
    animation-duration: 0.15s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
.text-font {
    font-size: 13px;
    /* color: #112d7b;*/
    font-family: "myb",Tahoma, Geneva, sans-serif;
    text-transform: uppercase;
}
/* Bob */
@-webkit-keyframes hvr-bob {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
    50% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
    }
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
@keyframes hvr-bob {
    0% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
    50% {
        -webkit-transform: translateY(-4px);
        transform: translateY(-4px);
    }
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
@-webkit-keyframes hvr-bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
@keyframes hvr-bob-float {
    100% {
        -webkit-transform: translateY(-8px);
        transform: translateY(-8px);
    }
}
.hvr-bob {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
}
.hvr-bob:hover, .hvr-bob:focus, .hvr-bob:active {
    -webkit-animation-name: hvr-bob-float, hvr-bob;
    animation-name: hvr-bob-float, hvr-bob;
    -webkit-animation-duration: .3s, 1.5s;
    animation-duration: .3s, 1.5s;
    -webkit-animation-delay: 0s, .3s;
    animation-delay: 0s, .3s;
    -webkit-animation-timing-function: ease-out, ease-in-out;
    animation-timing-function: ease-out, ease-in-out;
    -webkit-animation-iteration-count: 1, infinite;
    animation-iteration-count: 1, infinite;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-direction: normal, alternate;
    animation-direction: normal, alternate;
}
.btn-download {
    padding: 0px 12px;
    background:none;
    color:#db282e;
    margin-top: 10px;
}
.fallingLeaves {
    position: absolute;
    text-align: center;
    top: -15px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
}
.fallingLeaves span {
    display: inline-block;
    width: 60px;
    height: 64px;
    margin: -30px 40px 54px  -34px;
    opacity: 0.8;
    background:url("/images/nu-mai.png") no-repeat center center;
    background-size: 45%;
    -webkit-animation: fallingLeaves 14s infinite  linear;
    -moz-animation: fallingLeaves 14s infinite  linear;
}
.fallingLeaves span:nth-child(5n+5) {

    -webkit-animation-delay: 1.3s;
    -moz-animation-delay: 1.3s;
}
.fallingLeaves span:nth-child(3n+2) {

    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s;
}
.fallingLeaves span:nth-child(2n+5) {

    -webkit-animation-delay: 1.7s;
    -moz-animation-delay: 1.7s;
}

.fallingLeaves span:nth-child(3n+10) {

    -webkit-animation-delay: 2.7s;
    -moz-animation-delay: 2.7s;
}
.fallingLeaves span:nth-child(7n+2) {

    -webkit-animation-delay: 3.5s;
    -moz-animation-delay: 3.5s;
}
.fallingLeaves span:nth-child(4n+5) {

    -webkit-animation-delay: 5.5s;
    -moz-animation-delay: 5.5s;
}
.fallingLeaves span:nth-child(3n+7) {

    -webkit-animation-delay: 8s;
    -moz-animation-delay: 8s;
}
@-webkit-keyframes fallingLeaves {
    0% {
        opacity: 1;

        -webkit-transform: translate(0, 0px) rotateZ(0deg);
    }
    75% {
        opacity: 1;

        -webkit-transform: translate(100px, 600px) rotateZ(270deg);
    }
    100% {
        opacity: 0;

        -webkit-transform: translate(150px, 800px) rotateZ(360deg);
    }
}
@-moz-keyframes fallingLeaves {
    0% {
        opacity: 1;

        -webkit-transform: translate(0, 0px) rotateZ(0deg);
    }
    75% {
        opacity: 1;

        -webkit-transform: translate(100px, 600px) rotateZ(270deg);
    }
    100% {
        opacity: 0;

        -webkit-transform: translate(150px, 800px) rotateZ(360deg);
    }
}

.back-to-top {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background: rgba(0,0,0,.5);
    color: #FFF;
    width: 40px;
    height: 40px;
    line-height: 36px;
    text-align: center;
    border-radius: 50%;
    font-size: 160%;
    cursor: pointer;
    z-index: 9999999;
    display: none;
}

.img-text-header {
    left: -20px;
}
.img-text-header img{
    width:70%;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: #ee1c25;
}
.video-promistion{
    position: relative;
    z-index: 1
}
.video-promistion .img-holder{
    position: relative;
    visibility: hidden;
}
.video-promistion .iframe-youtube {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.video-promistion .iframe-youtube img{
    display: inline-block;
}
.list-footer{
    padding-right: 20%
}
.wrap-button-join {
    padding: 30px 0;
}
.wrap-button-join .button{
    display: block;
}
.inner-list-gthuong {
    padding: 10px;
    background: rgba(255,255,255, 0.5);
    border-radius: 5px;
}

.list-gthuong {
    padding: 10px;
    background: rgba(255,255,255, 0.5);
    border-radius: 5px;
    margin: 100px 0 60px 0;
}
.btn-tri-an {
    display: block;
    width: 140px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 999;
}
.list-img-chuyen-tien-don-tet {
    padding: 20px 10% 0 10%;
}
.dp-ib {
    /*display: inline-block;*/
}
.list-img-uu-dai-dich-vu-ngan-hang {
    padding: 10px 30px 0 30px;
}
.title-img-uu-dai-vay-ca-nhan{
    padding: 0 30px;
}
.box-uu-dai-khach-hang .row,
.box-vay-ca-nhan .row,
.box-uu-dai-dich-vu-ngan-hang .row{
    margin-left: 0;
    margin-right: 0;
}
.box-uu-dai-khach-hang [class*="col-"],
.box-vay-ca-nhan [class*="col-"],
.box-uu-dai-dich-vu-ngan-hang [class*="col-"]{
    padding-left: 0;
    padding-right: 0;
}
.list-img-chuyen-tien-don-tet .row,
.box-uu-dai-dich-vu-ngan-hang .row{
    margin-left: -5px;
    margin-right: -5px;
}
.list-img-chuyen-tien-don-tet [class*="col-"],
.box-uu-dai-dich-vu-ngan-hang [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px;
}
.left-week-gold.e-baking {
    padding-bottom: 15%;
}
.left-week-gold.vay-ca-nhan{
    padding-bottom: 10%;
}
.left-week-gold.tien-gui {
    padding-bottom: 12%;
}
.left-week-gold.e-baking .wellcom-year{
    bottom: -50px
}
.left-week-gold.vay-ca-nhan .wellcom-year{
    bottom: -30px
}
.left-week-gold.tien-gui .wellcom-year{
    bottom: -50px
}





.navbar-light .navbar-nav .nav-link{
    color: white!important;
}

.pull-right{
    float: right;
}

.card{
    background-color: transparent!important;
    border: none!important
}

.card .card-header {
    font-size: 1.2em;
    line-height: 32px;
    background-color: red;
    border-top: 1px solid white;
}

.card .card-header>* {
    display: inline-block;
}

.card .card-header span {
    color: #f6ec13;
    font-weight: 600;
    /*font-size: 30px;*/
    /*display: inline-block;*/
}


.original small {
    font-size: 14px;
    line-height: 14px;
    display: inline-block;
}

.discount small {
    font-size: 14px;
    line-height: 14px;
    display: inline-block;
}


.original span {
    text-decoration: line-through;
}

.card .card-body {
    font-family: jambono;
    color: #4f4e4b;
    border: none;
    padding-bottom: .25rem;
    background-color: #ffdd15;
}

.card .card-footer {
    background-color: transparent;
    padding-top: .25rem;
    border: none;
    position: relative;
    white-space: pre-line;
}

.card .discount {
    color: #c30e13;
}


.card-body .prize{
    font-size: 20px;
    margin-top: 0px!important;
    font-weight: 600;
}

.card-body .prize .sell{
    text-align: right;
}

.card .card-body .discount span {
    font-size: 25px;
    display: inline-block;
}

.original  span {
    font-size: 1.1em;
    display: inline-block;
}

.card .card-body small {
    font-size: 14px;
    line-height: 14px;
    display: inline-block;
}

.original {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transform: scale(.75);
    transform: scale(.75);
}

.original .ori{
    text-decoration: line-through;
}


.card .card-footer {
    background-color: transparent;
    padding-top: .25rem;
    border: none;
    position: relative;
    white-space: pre-line;
}

.card .card-footer ul {
    white-space: pre-line;
    padding: .75rem 1.25rem;
    margin-left: 0;
}

.card .card-footer ul li {
    position: relative;
    text-align: -webkit-match-parent;
}

.card .form-check{
    float: right;
}

.card .bor{
    border: 1px dashed white;
    margin-bottom: 10px;
    border-radius: 1em;
    padding: 3%;
}

.card .bor .increase {
    text-align: right;
    margin-top: 0;
}

.bottom-form .row{
    margin-bottom: 10px;
    margin-top: 20px;
}

.card button{
    /*color: #1dbb3f;*/
    border: 1px solid white;
}

.card button:hover{
    color: yellow;
    border: 1px solid yellow;
}

.terms .form-check-label{
    color: red;
}

.terms textarea{
    font-size: 10px;
    
}

.purchase{
    background-color:white ;
    padding: 1%;
    margin-bottom: 3rem!important;
}


.clearfix:before, .clearfix:after, .dl-horizontal dd:before, .dl-horizontal dd:after, .container:before, .container:after, .container-fluid:before, .container-fluid:after, .row:before, .row:after, .form-horizontal .form-group:before, .form-horizontal .form-group:after, .btn-toolbar:before, .btn-toolbar:after, .btn-group-vertical>.btn-group:before, .btn-group-vertical>.btn-group:after, .pager:before, .pager:after, .panel-body:before, .panel-body:after, .modal-footer:before, .modal-footer:after{
    content: " ";
    display: table;
}

.clearfix:after, .dl-horizontal dd:after, .container:after, .container-fluid:after, .row:after, .form-horizontal .form-group:after, .btn-toolbar:after, .btn-group-vertical>.btn-group:after, .nav:after, .navbar:after, .navbar-header:after, .navbar-collapse:after, .pager:after, .panel-body:after, .modal-footer:after{
    clear: both;
}

.top-middle{
    
    margin-top: 20px;
}

.main-background{
    background: url('/images/background.png') no-repeat top;
    background-size: cover;
    padding-top: 10px;
}

.img-responsive{
    width: 100%;
    height: auto;
}

.FAQ .column {
    float: left;
    width: 50%;
    font-size: 16px;

}

.FAQ .row:after {
    content: "";
    display: table;
    max-width: 600px;
    clear: both;
}

.has-error .valid-feedback{
    display: block!important;
    color : white;
}

.bor .has-error .valid-feedback{
    color : red;
}

.has-error {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    /*background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' …circle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E);*/
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.navbar-brand {
    width: 30%;
}

@media (max-width: 1199px) {

    .bg-header {
        background-size: 130px 80px;
    }

    h1{    font-size: 64px;}
    .mt-50{
        margin-top:0;
    }
    .list-gthuong{
        margin: 10px 0 30px 0
    }
}


@media (max-width: 990px) {
   
    .hope .banner{padding: 50px 0;}
    .mt-50{
        margin-top:0;
    }
    h1{    font-size: 54px;}
    .hope .donate{padding: 40px 30px 50px;}
    .hope .donate .fa{font-size: 70px;}
    .hope .supporters ul li{margin: 20px 8px;}
    .hope .join-us h2,.hope .join-us h2 span{font-size: 35px; margin-top: 0; line-height: 35px;}
    .hope .testimonial{margin: 0; margin-bottom: 30px;}
    .hope .testimonial.style1{margin-top: 0;}
    .navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .navbar-nav {
        margin: 0;
    }
    .item-footer+.item-footer {
        margin-left: 10px;
    }
}

@media (min-width: 786px) {
    .dua-right {
        margin-top: 20%;
        margin-right:-30%
    }
}

@media (max-width: 768px) {

    .navbar-brand img {
        max-width: 250px;
    }

    .dua-right {
        margin-top: 20%;
        margin-right:-25%
    }
    .wellcom-year .go {
        width: 45rem;
    }

    .modal-dialog {
        width: auto;
        max-width: 800px
    }

    .description-deal .light-bxo{
        display: none;
    }
    .height-fix{
        height: 126px;
    }
    .nav li ul
    {
        width:100%;
        position: relative;
    }
    h1{font-size: 42px;}
    .hope .join-us ul{margin: 40px 0;}
    .hope .supporters ul li{margin: 20px 16px;}
    .hope .supporters .info{
        border-bottom: 10px solid #bb3540;
        border-right: 0;
        margin: 0 auto;
        max-width: 35%;
    }
    .hope .supporters ul li{margin-bottom: 10px;}
    .hope .testimonials{padding: 40px 0;}
    .nav-menu-sub .nav-item+.nav-item {
        margin-left: 10px;
    }
    .lb-choose-service {
        white-space: normal;
    }
    .item-footer+.item-footer+.item-footer {
        margin-left: 0;
        display: block;
        margin-top: 5px;
    }
    .img-canh-mai.half{
        display: none;
    }
    .item-gthuong {
        margin-bottom: 20px;
    }
    .img-deal-don-tet{
        text-align: center
    }
    .img-uu-dai-khach-hang {
        margin-bottom: 20px;
    }
    .title-img-uu-dai-vay-ca-nhan {
        padding: 0;
    }
    .list-footer {
        text-align: left;
    }
    .web, .phone, .theochungtoi{
        font-size: 100%;
    }
    .igm-deal-content {
        text-align: center;
    }
    .inner-footer:before {
        margin-bottom: 15px;
    }
    .list-img-chuyen-tien-don-tet {
        padding: 20px 0;
    }
    .list-img-uu-dai-dich-vu-ngan-hang {
        padding: 10px 0;
    }
    .web, .phone {
        padding-left: 20px;
        line-height: 18px;
        background-size: 15px;
    }
    .face, .you{
        width: 15px;
        height: 15px;
        background-size: cover;
    }
    .theochungtoi{
        line-height: 18px
    }
    .inner-footer {
        padding-bottom: 10px
    }

    /*.button{*/
        /*font-size: 18px;*/
        /*padding: 10px 40px;*/
        /*margin-top:10px*/
    /*}*/
    .button-ct{
        font-size: 18px;
        padding: 10px 40px;
    }
    h1{
        font-size: 36px;
    }
    .hope .banner a.btn{margin-top: 20px;}
    .hope header a.logo,.hope header .phone{float: none !important; text-align: center; margin: 0 auto; display: table;}
    .hope .donate .fa{right: 30px; font-size: 68px;}

}

@media (max-width:560px){

    .top-middle{
        margin-top: 10%;
    }

    .wrap-home{
        background-size: 100% 100%;
    }

    .hope .join-us h2, .hope .join-us h2 span{font-size: 26px;}
    .hope .timeline .line:before{
        display: none !important;
    }
    .hope .timeline::after{display: none;}
    .hope .timeline .line{
        text-align: center !important;
        float: none;
        width: 100%;
        padding: 20px 30px;
    }
    .hope .timeline .line:nth-child(2){margin-top: 0;}
    .inner-form-contact {
        padding: 0;
        background: transparent;
        margin: 0 5%;
    }
    .img-mua-lan, .img-chu-cuoi {
        bottom: 70px;
    }
    .img-chu-cuoi{
        margin-right: -10%
    }
    .footer:before{
        height: 5px;
        top: -5px
    }
    .inner-footer:before{
        height: 5px;
    }

    /*.navbar-brand{*/
        /*width: 60%;*/
    /*}*/

    .week-gold .btn-deal{
        display: block !important;
        width: 100% !important;
    }
    .week-gold .btn-deal+.btn-deal{
        margin-left: 0 !important;
        margin-top: 20px
    }
    .title-contact{
        font-size: 120%
    }
    .title-choose-servce{
        font-size: 90%
    }
}


.inner-form-contact .main-form .top-form{
    background-color: red;
    padding: 1% 2%;
}
.inner-form-contact label{
    color: white
}

.bottom-form label{
    color: #414042;
}

.main-topic {
    position: relative;
    width: 100%;
    /* padding:50px; */
    
}



.image{
    position: relative;
    overflow: hidden;
}
.image.image1{
    width: 45%;
    height: auto;
    float: left;
    border-radius: 10px;
    margin:20px;
}

.main-topic p{
    color: #4f4e4d;
    line-height: 1.5em;
    text-align: justify;
    font-size: 1em;
}
.term{
    color: #4f4e4d;
    padding:2%
}

.term ul li{
    margin-top: 20px;
    font-size: 18px;
}

@media (max-width: 600px) {
    .image.image1{
        width: 90%;
    }

    .navbar-brand {
        width: 60%;
    }

   
}

.read-more-button{
    color : #ff0000
}

.songkran-bg{
    background: url(/images/bg.png) no-repeat top !important;
    background-size: cover;
    position: relative;
}


